import styled from 'styled-components';
import { fluidRange } from 'polished';

const Heading = styled.h1`
  display: inline-block;
  margin-bottom: 32px;
  font-weight: normal;
  line-height: 1.25;
  letter-spacing: 0.05em;
  color: ${({ theme, color }) => color ? theme.colors[color] : theme.colors.primary};

  ${({ theme, as }) => fluidRange(
    [
      {
        prop: 'font-size',
        fromSize: as === 'h1' ? '48px' : '32px',
        toSize: as === 'h1' ? '64px' : '48px',
      },
    ],
    theme.breakpoints.sm,
    theme.breakpoints.xxl,
  )}
`;

export default Heading;
