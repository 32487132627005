import styled from 'styled-components';
import { up } from 'styled-breakpoints';
import { fluidRange, rgba } from 'polished';

const fluidSpaces = (theme, prop) => fluidRange(
  [
    {
      prop: `${prop}-top`,
      fromSize: '64px',
      toSize: '120px',
    },
    {
      prop: `${prop}-bottom`,
      fromSize: '64px',
      toSize: '120px',
    },
  ],
  theme.breakpoints.sm,
  theme.breakpoints.lg,
);


export const SectionBase = styled.section`
  ${({ theme }) => fluidSpaces(theme, 'padding')}
`;

export const Section = styled(SectionBase)`
  & + .npt {
    padding-top: 0;
  }
`;

export const SectionColored = styled(SectionBase)`
  position: relative;
  background-color: ${rgba("#C9D0D9", 0.15)};

  ${up("md")} {
    &:after {
      content: "";
      display: ${({ line }) => line ? "block" : "none"};
      position: absolute;
      top: 30px;
      bottom: 30px;
      left: 50%;
      width: 1px;
      background:  #333333;
    }
  }
`;

export const SectionWithLine = styled(SectionBase)`
  background-image: linear-gradient(${({ theme }) => theme.colors.primary}, ${({ theme }) => theme.colors.primary});
  background-size: 15px 4px;
  background-position: right center;
  background-repeat: no-repeat;

  ${up('md')} {
    background-size: 48px 4px;
    background-position-x: left;
  }

  ${up('lg')} {
    background-size: 125px 4px;
  }
`;

export default Section;
